import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { XMarkIcon } from '@heroicons/react/24/outline';

function SignupModal({ onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password);
      onClose();
    } catch (error) {
      console.error("Error signing up:", error);
      alert(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      onClose();
    } catch (error) {
      console.error("Error signing up with Google:", error);
      alert(error.message);
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4" onClick={handleOutsideClick}>
      <div className="bg-gray-800 bg-opacity-80 p-8 rounded-lg max-w-sm w-full m-1 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-white">회원가입</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
            autoComplete="email"
          />
          <input
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
            autoComplete="new-password"
          />
          <input
            type="password"
            placeholder="비밀번호 확인"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
            autoComplete="new-password"
          />
          <button type="submit" className="w-full bg-blue-600 text-white p-2 rounded mb-4 hover:bg-blue-700">회원가입</button>
        </form>
        <button onClick={handleGoogleSignUp} className="w-full bg-red-500 text-white p-2 rounded mb-4 hover:bg-red-600">
          Google로 회원가입
        </button>
        <div className="flex justify-end text-xs">
          <button onClick={onClose} className="text-red-300 hover:underline">
            회원가입 취소
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignupModal;
