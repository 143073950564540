import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UrlShortener from './UrlShortener';
import Header from './components/Header';
import LoginModal from './components/LoginModal';
import SignupModal from './components/SignupModal';
import UrlHistory from './components/UrlHistory';
import Modal from './components/Modal';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, deleteUser } from "firebase/auth";
import { XMarkIcon } from '@heroicons/react/24/outline';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

initializeApp(firebaseConfig);

function App() {
  const [user, setUser] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    }, (error) => {
      console.error("Firebase auth error:", error);
      setShowErrorModal(true);
    });
  }, []);

  const handleDeleteAccount = async () => {
    if (window.confirm("정말로 계정을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.")) {
      try {
        const auth = getAuth();
        await deleteUser(auth.currentUser);
        alert("계정이 성공적으로 삭제되었습니다.");
      } catch (error) {
        alert("계정 삭제 중 오류가 발생했습니다: " + error.message);
      }
    }
  };

  const InfoModal = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" onClick={onClose}>
      <div className="bg-black bg-opacity-50 backdrop-blur-md p-8 rounded-lg max-w-sm" onClick={e => e.stopPropagation()}>
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-300 hover:text-white">
          <XMarkIcon className="h-6 w-6" />
        </button>
        <h2 className="text-xl font-bold mb-4 text-white">💎 T.R.I.P.O.D 수업 혁신 연구회</h2>
        <p className="mb-4 text-sm text-gray-200">
          T.R.I.P.O.D 수업 혁신 연구회는 수업-평가에 대한 균형감있는 스탠드로 교실 속 수업을 개선하고자 하는 모든 선생님들께 열려있습니다.
        </p>
        <a 
          href="https://slashpage.com/tripod/about" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-sm text-blue-300 hover:text-blue-200 hover:underline"
        >
          T.R.I.P.O.D 수업 혁신 연구회 바로가기
        </a>
        <p className="mt-4 text-sm text-gray-400">
          ✅ 제작: 이상선(indend007@gmail.com)
        </p>
      </div>
    </div>
  );

  const handleLoginError = (error) => {
    console.error("Login error:", error);
    setErrorMessage('로그인 정보를 확인해주세요.');
    setShowErrorModal(true);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 flex flex-col">
        <Header 
          user={user} 
          onLoginClick={() => setShowLoginModal(true)}
          onSignupClick={() => setShowSignupModal(true)}
        />
        <div className="flex-grow flex flex-col">
          <div className="w-full max-w-[600px] mx-auto flex flex-col flex-grow">
            <main className="flex flex-col p-4 flex-grow">
              <Routes>
                <Route path="/" element={
                  <div className="flex items-center justify-center flex-grow">
                    <div className="text-center">
                      <h1 className="text-2xl font-bold text-white mb-8">🔗 단축 URL 생성</h1>
                      <UrlShortener user={user} className="min-w-[540px]" />
                    </div>
                  </div>
                } />
                <Route path="/history" element={<UrlHistory user={user} />} />
              </Routes>
            </main>
            {showLoginModal && (
              <LoginModal 
                onClose={() => setShowLoginModal(false)} 
                onError={handleLoginError}
              />
            )}
            {showSignupModal && <SignupModal onClose={() => setShowSignupModal(false)} />}
          </div>
        </div>
        <footer className="text-center py-4 flex justify-center items-center">
          <button 
            onClick={() => setShowInfoModal(true)}
            className="text-white text-xs hover:underline focus:outline-none"
          >
            ⓒ2024. TRPD.ME a.k.a TRIPOD
          </button>
          {user && (
            <button 
              onClick={handleDeleteAccount}
              className="text-red-300 text-xs hover:underline focus:outline-none ml-4"
            >
              회원 탈퇴
            </button>
          )}
        </footer>
        {showInfoModal && <InfoModal onClose={() => setShowInfoModal(false)} />}
        <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)}>
          <h2 className="text-xl font-bold mb-4">😲 로그인 오류</h2>
          <p>{errorMessage}</p>
        </Modal>
      </div>
    </Router>
  );
}

export default App;
