import React, { useEffect, useState, useCallback } from 'react';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, startAfter, deleteDoc, doc } from "firebase/firestore";
import { ClipboardIcon, QrCodeIcon, XMarkIcon, LockClosedIcon, ExclamationCircleIcon, CogIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';

function UrlHistory({ user }) {
  const [urls, setUrls] = useState([]);
  const [showQRModal, setShowQRModal] = useState(false);
  const [currentQRUrl, setCurrentQRUrl] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [customUrl, setCustomUrl] = useState('');
  const [editingLongUrl, setEditingLongUrl] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  const fetchUrls = useCallback(async (page = 1) => {
    if (user) {
      const db = getFirestore();
      const urlsRef = collection(db, "urls");
      
      // 총 문서 수 가져오기
      const countQuery = query(urlsRef, where("userId", "==", user.uid));
      const countSnapshot = await getDocs(countQuery);
      const totalItems = countSnapshot.size;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));

      // 페이지네이션을 위한 쿼리
      let q = query(
        urlsRef,
        where("userId", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(itemsPerPage)
      );

      if (page > 1) {
        const lastVisibleSnapshot = await getDocs(
          query(urlsRef, where("userId", "==", user.uid), orderBy("createdAt", "desc"), limit((page - 1) * itemsPerPage))
        );
        const lastVisible = lastVisibleSnapshot.docs[lastVisibleSnapshot.docs.length - 1];
        q = query(q, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(q);
      const urlData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), redirectCount: doc.data().redirectCount || 0 }));
      setUrls(urlData);
      setCurrentPage(page);
    }
  }, [user]);

  useEffect(() => {
    fetchUrls();
  }, [fetchUrls]);

  const handlePageChange = (newPage) => {
    fetchUrls(newPage);
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('URL이 클립보드에 복사되었습니다!');
    });
  };

  const openQRModal = (url) => {
    setCurrentQRUrl(url);
    setShowQRModal(true);
  };

  const openShortUrl = (shortUrl) => {
    window.open(`${window.location.origin}${shortUrl}`, '_blank');
  };

  const formatDate = (date) => {
    return new Date(date.seconds * 1000).toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowQRModal(false);
      setShowErrorModal(false);
    }
  };

  const startEditing = (id, currentShortUrl, currentLongUrl) => {
    setEditingId(id);
    setCustomUrl(currentShortUrl.slice(1)); // Remove the leading '/'
    setEditingLongUrl(currentLongUrl);
  };

  const validateAndSaveCustomUrl = async (id) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/validateCustomUrl`, {
        customUrl: `/${customUrl}`,
        urlId: id
      });
      if (response.data.isValid) {
        // Update the URL in the database
        await axios.post(`${process.env.REACT_APP_API_URL}/updateCustomUrl`, {
          urlId: id,
          customUrl: `/${customUrl}`,
          longUrl: editingLongUrl
        });
        // Update local state
        setUrls(urls.map(url => 
          url.id === id ? { ...url, shortUrl: `/${customUrl}`, longUrl: editingLongUrl } : url
        ));
        setEditingId(null);
      } else {
        setErrorMessage('중복되는 단축 URL이 있습니다. 변경해주세요.');
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error('Error validating custom URL:', error);
      setErrorMessage('URL 검증 중 오류가 발생했습니다.');
      setShowErrorModal(true);
    }
  };

  const cancelEditing = () => {
    setEditingId(null);
    setCustomUrl('');
    setEditingLongUrl('');
  };

  const deleteUrl = async (id) => {
    if (window.confirm('이 URL을 삭제하시겠습니까?')) {
      try {
        const db = getFirestore();
        await deleteDoc(doc(db, 'urls', id));
        setUrls(urls.filter(url => url.id !== id));
        alert('URL이 성공적으로 삭제되었습니다.');
      } catch (error) {
        console.error('Error deleting URL:', error);
        setErrorMessage('URL 삭제 중 오류가 발생했습니다.');
        setShowErrorModal(true);
      }
    }
  };

  const formatShortUrl = (url) => {
    return url.replace(/^(https?:\/\/)/, '');
  };

  if (!user) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-white">
        <LockClosedIcon className="mt-10 h-10 w-10 mb-4 text-white text-bold" />
        <p className="text-gray-300 text-sm">단축 URL 관리를 위해서 로그인 해주세요.</p>
        <h2 className="bg-black opacity-50 rounded-3xl p-1 px-4 text-sm mt-4 font-bold">TRPD.ME</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-2xl font-bold text-white mb-4">URL 히스토리</h2>
      {urls.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full text-white">
          <ExclamationCircleIcon className="mt-10 h-10 w-10 mb-4 text-white text-bold" />
          <p className="text-gray-300 text-sm">아직 생성된 URL이 없습니다.</p>
          <p className="text-gray-300 text-sm mt-2">첫 번째 단축 URL을 만들어보세요!</p>
          <h2 className="bg-black opacity-50 rounded-3xl p-1 px-4 text-sm mt-4 font-bold">TRPD.ME</h2>
        </div>
      ) : (
        <>
          <ul className="rounded-lg space-y-4">
            {urls.map(url => (
              <li key={url.id} className="text-white bg-black bg-opacity-5 border-[#eeeeee10] p-4 pl-6 last:border-b-0 transition duration-300 ease-in-out hover:bg-white hover:bg-opacity-10 rounded-lg">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                  <div className="mb-2 sm:mb-0 flex-grow">
                    {editingId === url.id ? (
                      <div className="relative bg-opacity-5 xs:max-w-full sm:max-w-full rounded-md overflow-hidden flex items-center">
                        <div className="flex-grow">
                          <input
                            type="text"
                            value={customUrl}
                            onChange={(e) => setCustomUrl(e.target.value)}
                            className="w-full px-3 pl-1 py-1 bg-transparent text-white placeholder-gray-400 focus:outline-none"
                            placeholder="Custom short URL"
                          />
                          <input
                            type="text"
                            value={editingLongUrl}
                            onChange={(e) => setEditingLongUrl(e.target.value)}
                            className="w-full px-3 pl-1 py-1 text-sm bg-transparent text-blue-300 placeholder-gray-400 focus:outline-none rounded-[0px] border-t border-[#ffffff30]"
                            placeholder="Long URL"
                          />
                        </div>
                        <button
                          onClick={cancelEditing}
                          className="flex-shrink-0 ml-2 mr-6 text-white rounded-full p-1 hover:bg-black opacity-50 transition-all duration-200"
                          title="취소"
                        >
                          <XMarkIcon className="h-5 w-5 text-white" />
                        </button>
                      </div>
                    ) : (
                      <div>
                        <span 
                          className="font-semibold text-[#44e3cc] cursor-pointer hover:underline"
                          onClick={() => openShortUrl(url.shortUrl)}
                        >
                          {formatShortUrl(`${window.location.origin}${url.shortUrl}`)}
                        </span>
                        <br />
                        <a href={url.longUrl} target="_blank" rel="noopener noreferrer" className="pr-2 word-break break-all inline-block overflow-hidden text-blue-300 hover:text-blue-200 hover:underline text-sm">
                          {url.longUrl}
                        </a>
                      </div>
                    )}
                    <div className="flex items-center space-x-2 mt-1">
                      <span className="text-xs text-gray-100">
                        {formatDate(url.createdAt)}
                      </span>
                      {url.redirectCount > 0 && (
                        <span className="text-[10px] w-fit text-gray-100 bg-black bg-opacity-30 rounded-full px-[8px] py-[2px]">
                          {`${url.redirectCount}회 사용됨`}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end space-x-1 mt-2 sm:mt-0">
                    {editingId === url.id ? (
                      <button
                        onClick={() => validateAndSaveCustomUrl(url.id)}
                        className="p-2 rounded-md hover:bg-black hover:bg-opacity-50 transition duration-300"
                        title="저장"
                      >
                        <CheckIcon className="h-5 w-5 text-white" />
                      </button>
                    ) : (
                      <button
                        onClick={() => startEditing(url.id, url.shortUrl, url.longUrl)}
                        className="p-2 rounded-md hover:bg-black hover:bg-opacity-50 transition duration-300"
                        title="설정"
                      >
                        <CogIcon className="h-5 w-5 text-white" />
                      </button>
                    )}
                    <button
                      onClick={() => copyToClipboard(`${window.location.origin}${url.shortUrl}`)}
                      className="p-2 rounded-md hover:bg-black hover:bg-opacity-50 transition duration-300"
                      title="단축 URL 복사"
                    >
                      <ClipboardIcon className="h-5 w-5 text-white" />
                    </button>
                    <button
                      onClick={() => openQRModal(url.longUrl)}
                      className="p-2 rounded-md hover:bg-black hover:bg-opacity-50 transition duration-300"
                      title="원본 URL의 QR 코드 생성"
                    >
                      <QrCodeIcon className="h-5 w-5 text-white" />
                    </button>
                    <button
                      onClick={() => deleteUrl(url.id)}
                      className="p-2 rounded-md hover:bg-black hover:bg-opacity-50 transition duration-300"
                      title="URL 삭제"
                    >
                      <TrashIcon className="h-5 w-5 text-white" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-4 flex justify-center">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`mx-1 px-2 min-w-[25px] py-1 text-sm rounded ${
                  currentPage === page
                    ? 'bg-black opacity-50 text-white'
                    : 'text-white hover:text-white hover:bg-black opacity-50'
                }`}
              >
                {page}
              </button>
            ))}
          </div>
        </>
      )}

      {showQRModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" onClick={handleOutsideClick}>
          <div className="bg-white p-8 rounded-lg relative">
            <button
              onClick={() => setShowQRModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
            <h3 className="text-xl font-bold mb-4">QR 코드</h3>
            <QRCodeSVG value={currentQRUrl} size={256} />
          </div>
        </div>
      )}

      {showErrorModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" onClick={handleOutsideClick}>
          <div className="bg-white p-8 rounded-lg relative">
            <button
              onClick={() => setShowErrorModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
            <h3 className="text-xl font-bold mb-4">오류</h3>
            <p>{errorMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UrlHistory;
