import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { ChevronDownIcon, HomeIcon, ClockIcon, ArrowRightOnRectangleIcon, ArrowLeftOnRectangleIcon, UserPlusIcon } from '@heroicons/react/24/outline';

function Header({ user, onLoginClick, onSignupClick }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-opacity-10 p-4 max-w-[400px] mx-auto flex justify-center">
      <div className="flex justify-center items-center relative max-w-sm mx-auto">
        <Link to="/" className="text-white text-xl font-bold">*T.R.I.P.O.D.</Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white focus:outline-none ml-2"
        >
          <ChevronDownIcon className={`h-6 w-6 transform transition-transform duration-300 ${isMenuOpen ? 'rotate-180' : ''}`} />
        </button>
      </div>
      <div className={`absolute top-[60px] left-0 right-0 bg-black bg-opacity-20 backdrop-blur-md shadow-lg rounded-lg transition-all duration-300 ease-in-out z-20 transform origin-top ${isMenuOpen ? 'menu-slide-down' : 'menu-slide-up'}`} style={{ maxWidth: '340px', margin: '0 auto' }}>
        <nav className="py-4">
          <ul className="flex flex-wrap justify-center text-sm text-white gap-8">
            <li>
              <Link to="/" className="flex flex-col items-center text-white hover:text-blue-500 min-w-[50px]" onClick={() => setIsMenuOpen(false)}>
                <HomeIcon className="h-6 w-6 mb-1" />
                <span>홈</span>
              </Link>
            </li>
            {user && (
              <li>
                <Link to="/history" className="flex flex-col items-center text-white hover:text-blue-500 min-w-[40px]" onClick={() => setIsMenuOpen(false)}>
                  <ClockIcon className="h-6 w-6 mb-1" />
                  <span>URL 히스토리</span>
                </Link>
              </li>
            )}
            {user ? (
              <li>
                <button onClick={() => { getAuth().signOut(); setIsMenuOpen(false); }} className="flex flex-col items-center text-white hover:text-blue-500  min-w-[40px]">
                  <ArrowRightOnRectangleIcon className="h-6 w-6 mb-1" />
                  <span>로그아웃</span>
                </button>
              </li>
            ) : (
              <>
                <li>
                  <button onClick={() => { onLoginClick(); setIsMenuOpen(false); }} className="flex flex-col items-center text-white hover:text-blue-500  min-w-[40px]">
                    <ArrowLeftOnRectangleIcon className="h-6 w-6 mb-1" />
                    <span>로그인</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => { onSignupClick(); setIsMenuOpen(false); }} className="flex flex-col items-center text-white hover:text-blue-500  min-w-[40px]">
                    <UserPlusIcon className="h-6 w-6 mb-1" />
                    <span>회원가입</span>
                  </button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
