import React, { useState } from 'react';
import axios from 'axios';
import { ClipboardIcon } from '@heroicons/react/24/outline';

function UrlShortener({ user }) {
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setShortUrl('');
    setIsLoading(true);

    try {
      const payload = { longUrl };
      if (user) {
        payload.userId = user.uid;
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/urlShortener`, payload);
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setShortUrl(response.data.shortUrl);
      }
    } catch (error) {
      console.error('URL 단축 중 오류 발생:', error);
      setError('URL 단축 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (url) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(url).then(() => {
        alert('단축 URL이 클립보드에 복사되었습니다!');
      }).catch(() => {
        fallbackCopyTextToClipboard(url);
      });
    } else {
      fallbackCopyTextToClipboard(url);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? '성공적으로 복사되었습니다.' : '복사에 실패했습니다.';
      alert(msg);
    } catch (err) {
      console.error('복사 중 오류 발생:', err);
      alert('복사에 실패했습니다.');
    }
    document.body.removeChild(textArea);
  };

  return (
    <div className="w-full max-w-[340px] mb-20">
      <form onSubmit={handleSubmit} className="mb-4">
        <input
          type="url"
          value={longUrl}
          onChange={(e) => setLongUrl(e.target.value)}
          placeholder="*줄이고 싶은 URL을 입력하세요"
          required
          className="min-w-[340px] w-full px-4 py-2 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-20 text-white placeholder-gray-300"
        />
        {error && <p className="mt-4 mb-2 text-white rounded-md p-1 text-sm">😥{error}</p>}
        <button
          type="submit"
          disabled={isLoading}
          className={`w-full mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              단축 URL 생성 중...
            </span>
          ) : '생성하기'}
        </button>
      </form>
      {shortUrl && (
        <div className="bg-white bg-opacity-20 pt-2 pb-1 pl-2 pr-2 rounded-md">
          <p className="text-sm font-medium text-white pt-1 pb-0">🍀 단축된 URL</p>
          <div className="flex items-center">
            <a
              href={`${window.location.origin}${shortUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-300 hover:text-blue-200 hover:underline mr-2 flex-grow"
            >
              {`${window.location.origin}${shortUrl}`}
            </a>
            <button
              onClick={() => copyToClipboard(`${window.location.origin}${shortUrl}`)}
              className="p-2 rounded-md hover:bg-black hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
            >
              <ClipboardIcon className="h-5 w-5 text-white" />
            </button>
          </div>
        </div>
      )}
      {shortUrl && !user && (
        <div className="flex items-center justify-center text-yellow-300 text-sm mt-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
          <p>비회원의 단축 URL은 일주일 뒤 자동 만료됩니다.</p>
        </div>
      )}
    </div>
  );
}

export default UrlShortener;
